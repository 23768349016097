<template>
	<header :class="{ 'banner--transparent': !isLoggedIn }" class="banner">
		<div class="constrained">
			<div class="flex">
				<nuxt-link class="banner__logo" to="/">
					<img
						alt=""
						class="banner__logo__img"
						height="48"
						src="/img/gms-myprofile-blue-01.svg"
						width="48"
					/>
					<span class="banner__logo__text">
						{{ t('PORTAL') }}
					</span>
				</nuxt-link>
				<menu-component :menu-items="[]"/>
			</div>
		</div>
	</header>
</template>
<script lang="ts">
import {mapState} from "pinia";
import MenuComponent from '../Menu.vue'
import {useUserStore} from "~/stores/user";

export default defineComponent({
	components: {MenuComponent},
  setup() {
    const { t } = useTranslation()

    return {
      t
    }
  },
	computed: {
		...mapState(useUserStore, ['isLoggedIn']),
	},
})
</script>
